<template>
  <custom-input
    v-model="value"
    :label="$t(labelPath)"
    :placeholder="$t('organisation.form.site.placeholder')"
    :disabled="disabled"
    :invalid-feedback="errorMessage"
    :state="errorMessage ? false : null"
  />
</template>

<script setup lang="ts">
import CustomInput from '~/ui/inputs/CustomInput.vue'

defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
})

const labelPath = 'organisation.form.site.label'

const { value, errorMessage } = useField('site', {
  urlWithoutProtocol: true,
}, {
  label: useI18n().t(labelPath),
})

</script>
