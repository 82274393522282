<template>
  <custom-button
    variant="outline"
    class="border-none h-full max-h-[38px] w-full rounded-none hover:text-primary active:!text-primary"
    :class="{'!text-primary bg-neutral-100 ' : isVisible, '!text-primary': isChildrenSelected}"
    @click="toggleVisible"
  >
    <template
      v-if="item.icon"
      #prefix
    >
      <nuxt-icon
        :name="item.icon"
        class="inline text-[24px]"
      />
    </template>
    <span
      class="ml-2 text-[18px]"
    >
      {{ t(item.title) }}
    </span>
    <div class="w-full flex justify-end ">
      <nuxt-icon
        :name="isVisible ? 'common/arrows/chevron-down' : 'common/arrows/chevron-right'"
        class="inline"
      />
    </div>
  </custom-button>
  <div
    v-if="isVisible"
    class="pl-[15px] pr-[39px] h-[2px] bg-neutral-100"
  >
    <div class="border-b-2" />
  </div>
  <transition>
    <div v-if="isVisible">
      <div
        v-for="(navItem, i) in item.children"
        :key="navItem.title"
        :class="{'bg-neutral-100' : isVisible}"
      >
        <component
          :is="navMenuItemsLinkOrGroup(navItem)"
          :item="navItem"
          :class="{'hover:!text-primary': !navItem.children}"
        />
        <div
          v-if="i !== item.children.length -1"
          class="!border-b-2 w-[266px] ml-[15px] mt-[2px]"
        />
      </div>
    </div>
  </transition>
</template>
<script setup lang="ts">
import CustomButton from '~/ui/buttons/CustomButton.vue'
import NavMenuItemLink from '~/modules/nav-menu/components/nav-menu-items/NavMenuItemLink.vue'
import NavMenuItemGroup from '~/modules/nav-menu/components/nav-menu-items/NavMenuItemGroup.vue'
import { NavGroupItem } from '~/modules/nav-menu/types/NavGroupItem.type'
import { NavLinkItem } from '~/modules/nav-menu/types/NavLinkItem.type'

const props = defineProps({
  item: {
    type: Object as PropType<NavGroupItem>,
    required: true,
  },
})

const { t } = useI18n()

const isVisible = ref(false)
const toggleVisible = () => {
  isVisible.value = !isVisible.value
}

const actualRoute = computed(() => useRoute().fullPath)

const isChildrenSelected = computed(() => {
  if (props.item?.children) {
    return props.item.children.some((child: NavGroupItem | NavLinkItem) => (actualRoute.value === '/' ? 'index' : actualRoute.value) === child?.routeName)
  }
  return false
})

const navMenuItemsLinkOrGroup = (item: NavGroupItem | NavLinkItem) => {
  if (item.children) {
    return NavMenuItemGroup
  }
  return NavMenuItemLink
}
</script>
<style scoped lang="scss">
.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
