<template>
  <custom-dropdown
    body-class="p-6 mt-8"
    :button-class="[{'bg-primary-light' : color === 'primary', 'text-primary': color === 'primary', '!bg-transparent' : color === 'default'}, 'whitespace-nowrap items-center font-semibold']"
    :size="isMd ? 'sm' : 'none'"
    dropdown-class="min-w-[80px]"
    :display-dropdown-arrow="isMd"
    right
  >
    <template #triggerContent>
      <nuxt-icon
        :name="`flags/${$i18n.locale}`"
        class="text-[24px] mr-2"
        filled
      />
      <span>
        {{ $i18n.locale.toUpperCase() }}
      </span>
      <span class="mx-1">•</span>
      <span>
        {{ $t(`currency.options.${selectedCurrency}`) }}
      </span>
      <!--      <span class="mx-1">•</span>-->
      <!--      <span>-->
      <!--        {{ $t(`common.unitsOfMeasurement.options.${selectedMeasure}`) }}-->
      <!--      </span>-->
    </template>
    <div class="flex flex-col gap-y-6">
      <div class="flex flex-col gap-y-3 items-start">
        <h2 class="text-base font-semibold">
          {{ $t('common.language') }}
        </h2>
        <toggle-language />
      </div>
      <div class="flex flex-col gap-y-3 items-start">
        <h2 class="text-base font-semibold">
          {{ $t('currency.label') }}
        </h2>
        <app-currency-toggle />
      </div>
      <!--      <div class="flex flex-col gap-y-3 items-start">-->
      <!--        <h2 class="text-base font-semibold">-->
      <!--          {{ $t('common.unitsOfMeasurement.label') }}-->
      <!--        </h2>-->
      <!--        <app-measure-toggle />-->
      <!--      </div>-->
    </div>
  </custom-dropdown>
</template>

<script setup lang="ts">
import { useAppStateStore } from '~/store/app'
import CustomDropdown from '~/ui/dropdowns/CustomDropdown.vue'
import AppCurrencyToggle from '~/components/app/AppCurrencyToggle.vue'
import AppMeasureToggle from '~/components/app/AppMeasureToggle.vue'
import ToggleLanguage from '~/modules/app-language/components/ToggleLanguage.vue'

defineProps({
  color: {
    type: String as PropType<'default' | 'primary'>,
    default: 'default',
  },
})

const appStateStore = useAppStateStore()

const selectedCurrency = computed(() => appStateStore.currency)

const selectedMeasure = computed(() => appStateStore.measure)
</script>
