<template>
  <custom-button
    variant="withoutBorders"
    class="flex font-medium items-center gap-x-2 text-lg"
    color="danger"
    @click="logout"
  >
    <nuxt-icon
      name="actions/exit"
      class="text-[24px]"
    />
    <span>{{ $t('navMenu.exit') }}</span>
  </custom-button>
</template>

<script setup lang="ts">

import CustomButton from '~/ui/buttons/CustomButton.vue'
import { AuthService } from '~/modules/auth/service/AuthService'
import { ROUTES_NAMES } from '~/router/constants/Router.routesNames'

const authService = new AuthService()

const emits = defineEmits<{(e: 'onLogout'): void}>()
const logout = (): void => {
  authService.logout()
    .then(() => {
      navigateTo({ name: ROUTES_NAMES.home })
      emits('onLogout')
      window.location.reload()
    })
}
</script>
