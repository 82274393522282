<template>
  <div
    class="fixed bottom-[90px] z-[100] md:bottom-6 left-0 md:left-1/2 md:-translate-x-1/2 w-full md:w-3/4 xl:w-1/2 max-md:px-6"
  >
    <custom-toast
      v-if="!isCookieAccepted"
      variant="info"
      class="flex-col lg:flex-row gap-y-3 max-lg:justify-center"
      :toast-title="$t('cookie.title')"
      text-container-class="max-lg:gap-y-3 max-lg:items-center"
    >
      <template #icon>
        <div class="shrink-0 w-[38px] h-[38px] bg-primary flex items-center justify-center rounded-full">
          <nuxt-icon
            name="common/cookie"
            class="text-[24px] text-white"
            filled
          />
        </div>
      </template>
      <template #close>
        <custom-button
          class="shrink-0 font-semibold"
          variant="filled"
          pill
          @click="isCookieAccepted = true"
        >
          {{ $t('cookie.accept') }}
        </custom-button>
      </template>
      <p class="max-md:text-center font-medium text-neutral-750">
        {{ $t('cookie.text') }}
        <nuxt-link
          class="text-primary"
          target="_blank"
          :to="ROUTES_NAMES.privatePolicy"
        >{{
          $t('cookie.readMore')
        }}</nuxt-link></p>
    </custom-toast>
  </div>
</template>

<script setup lang="ts">
import CustomToast from '~/ui/toasts/CustomToast.vue'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import { ref } from 'vue'
import { useNuxtApp } from '#app'
import { QdStorage } from '~/common/storage/interfaces/QdStorage'
import { ROUTES_NAMES } from '~/router/constants/Router.routesNames'

const itemKey = 'isCookieAccepted'
const storage: QdStorage = useNuxtApp().$appStorage
const isCookieAccepted = ref(false)

storage.getItem<boolean | null>(itemKey)
  .then(value => {
    isCookieAccepted.value = !!value
  })

watch(isCookieAccepted, value => {
  storage.setItem(itemKey, value)
})
</script>
