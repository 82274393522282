<script setup lang="ts">
import { MainMenuNavLinks } from '~/modules/main-menu/types/MainMenuNavLinks'
import { showAuthModalOrCallback } from '~/modules/auth/helpers/showAuthModalOrCallback'
import { SHARED_ROUTES } from '~/router/constants/Router.authAndGuestRoutes'
import { ObjectHelper } from '~/common/helpers/object'
import useOrganisationStore from '~/modules/organisation/store'

const props = defineProps({
  links: {
    type: Array as PropType<Array<MainMenuNavLinks>>,
    required: true,
  },
})

const organisationStore = useOrganisationStore()

const actualRoute = computed(() => useRoute().name)

const navigate = (routeName: string) => {
  if (actualRoute.value === routeName) {
    return
  }

  if (SHARED_ROUTES.includes(routeName as typeof SHARED_ROUTES[number])) {
    navigateTo({ name: routeName })
    return
  }

  showAuthModalOrCallback(() => {
    navigateTo({ name: routeName })
  })
}

const linksForShow = computed<Array<MainMenuNavLinks & {component: string, bind: Record<string, any>}>>(() => {
  const copiedLinks = ObjectHelper.copy(props.links)

  return copiedLinks.map(link => {
    if (SHARED_ROUTES.includes(link.link as typeof SHARED_ROUTES[number]) || organisationStore.isOrganisationStatusGranted) {
      return {
        ...link,
        component: resolveComponent('NuxtLink') as string,
        bind: {
          to: { name: link.link },
        },
      }
    }
    return {
      ...link,
      component: 'button',
      bind: {
        onClick: () => { navigate(link.link) },
      },
    }
  })
})

</script>

<template>
  <div class="flex">
    <template
      v-for="(link, inx) in linksForShow"
      :key="inx"
    >
      <component
        :is="link.component"
        v-bind="link.bind"
        class="font-semibold !text-black cursor-pointer"
        :class="{'border-b' : actualRoute === link.link}"
      >
        {{ link.title }}
      </component>
    </template>
  </div>
</template>
