<template>
  <bottom-fixed-menu class="flex justify-between py-4 px-6 items-center z-10 font-semibold">
    <custom-button
      v-for="(link, id) in links"
      v-bind="getButtonOrLinkAttrs(link.name)"
      :key="id"
      variant="withoutBorders"
      color="none"
      size="none"
      class="hover:text-primary flex flex-col items-center"
    >
      <div class="w-[38px] h-[38px] flex items-center justify-center">
        <nuxt-icon
          :name="link.icon"
          class="text-3xl stroke-0"
        />
      </div>
      <p>
        {{ link.label }}
      </p>
    </custom-button>
    <custom-button
      size="none"
      color="none"
      class="w-[45px] h-[45px] flex flex-col items-center gap-1"
      round
      @click="toggleNavMenu(true)"
    >
      <client-only>
        <font-awesome-icon
          icon="fa-bars"
          size="2x"
        />
      </client-only>
      <p>
        {{ t('common.more') }}
      </p>
    </custom-button>
  </bottom-fixed-menu>
</template>

<script setup lang="ts">
import { ROUTES_NAMES } from '~/router/constants/Router.routesNames'
import BottomFixedMenu from '~/ui/menus/BottomFixedMenu.vue'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import { useNavMenuStore } from '~/modules/nav-menu/store'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import useOrganisationStore from '~/modules/organisation/store'
import { showAuthModalOrCallback } from '~/modules/auth/helpers/showAuthModalOrCallback'
import { useUserStore } from '~/modules/user/store'
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'

const { t } = useI18n()
const userStore = useUserStore()
const organisationStore = useOrganisationStore()
const navStore = useNavMenuStore()

const links = [
  { name: `${ROUTES_NAMES.home}`, icon: 'common/home', label: t('pageTitles.home') },
  { name: `${ROUTES_NAMES.clients}`, icon: 'common/people', label: t('pageTitles.clients') },
  { name: `${ROUTES_NAMES.suggestionsManagement}`, icon: 'building/building-group', label: t('pageTitles.suggestions') },
  { name: ROUTES_NAMES.market, icon: 'entities/building', label: t('pageTitles.market') },
] as Array<{ name: string, icon: string, label: string }>

const navigate = (routeName: string) => {
  showAuthModalOrCallback(() => {
    navigateTo({ name: routeName })
  })
}

const getButtonOrLinkAttrs = (routeName: string) => {
  if (routeName === useRoute().name) {
    return {
      to: { name: routeName },
      component: resolveComponent('NuxtLink') as string,
      exactActiveClass: 'text-primary',
    }
  }

  if (organisationStore.isOrganisationStatusGranted) {
    return {
      to: { name: routeName },
      component: resolveComponent('NuxtLink') as string,
      exactActiveClass: 'text-primary',
    }
  }
  return {
    onClick: () => { navigate(routeName) },
  }
}

const toggleNavMenu = (value: boolean) => {
  if (userStore.isUserAuthorized) {
    navStore.toggleNavMenuDisplaying(value)
    return
  }

  navStore.toggleUnauthorizedNavMenuDisplaying(value)
}
</script>
