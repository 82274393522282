<template>
  <custom-input
    v-model="value"
    required
    type="text"
    :label="$t(labelPath)"
    :placeholder="$t('organisation.form.name.placeholder')"
    :disabled="disabled"
    :invalid-feedback="errorMessage"
    :state="errorMessage ? false : null"
  />
</template>

<script setup lang="ts">

import CustomInput from '~/ui/inputs/CustomInput.vue'

defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
})

const labelPath = 'organisation.form.name.label'

const { value, errorMessage } = useField('name', {
  required: true,
}, {
  label: useI18n().t(labelPath),
})
</script>
