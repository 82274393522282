<script setup lang="ts">

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { onClickOutside } from '@vueuse/core'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import CustomSidebar from '~/ui/sidebars/CustomSidebar.vue'
import { useNavMenuStore } from '~/modules/nav-menu/store'
import { useAppStateStore } from '~/store/app'
import CustomDrawer from '~/ui/drawers/CustomDrawer.vue'

import NavMenuItems from '~/modules/nav-menu/components/nav-menu-items/NavMenuItems.vue'
import { NAV_MENU_UNAUTHORIZED_ITEMS } from '~/modules/nav-menu/constants/NavMenuUnauthorizedItems'

const store = useNavMenuStore()

const appStore = useAppStateStore()

const isDisplayNavMenu = computed({
  get: () => store.isDisplayUnauthorizedNavMenu,
  set: value => {
    store.toggleUnauthorizedNavMenuDisplaying(value)
  },
})
const sideBar = ref<HTMLDivElement | null>(null)

const navButton = ref<HTMLButtonElement | null>(null)

const isMd = computed(() => appStore.breakpoints.isMd)

onClickOutside(sideBar, () => {
  if (appStore.openElementsCount.modals === 0) {
    store.toggleUnauthorizedNavMenuDisplaying(false)
  }
}, { ignore: [navButton] })

</script>

<template>
  <div
    class="h-main-menu-height flex items-center"
  >
    <custom-button
      ref="navButton"
      class="cursor-pointer text-primary max-h-none"
      variant="withoutBorders"
      @click="store.toggleUnauthorizedNavMenuDisplaying(!isDisplayNavMenu)"
    >
      <client-only>
        <font-awesome-icon
          class="w-[25px]"
          :icon="isDisplayNavMenu ? 'fa-xmark' : 'fa-bars'"
          :size="isDisplayNavMenu ? '3x' : '2x'"
        />
      </client-only>
    </custom-button>
  </div>
  <custom-sidebar
    v-if="isDisplayNavMenu && isMd"
    ref="sideBar"
    class="qd-nav-menu rounded-2xl w-[362px]"
  >
    <div class="flex flex-col">
      <nav-menu-items :items="NAV_MENU_UNAUTHORIZED_ITEMS" />
    </div>
  </custom-sidebar>
  <custom-drawer
    v-if="isDisplayNavMenu && !isMd"
    v-model="isDisplayNavMenu"
    full-height
  >
    <div class="h-full flex flex-col">
      <nav-menu-items :items="NAV_MENU_UNAUTHORIZED_ITEMS" />
    </div>
  </custom-drawer>
</template>
