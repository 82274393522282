<template>
  <footer class="max-xl:px-[20px]">
    <div class="flex max-lg:flex-col-reverse items-center justify-between pt-[40px] border-t-2 border-neutral-150 xl:max-w-[80%] w-full mx-auto gap-y-[40px]">
      <div class="flex flex-col font-semibold text-neutral-500">
        <span>{{ $t('footer.copyright.title', { year: fullYear }) }}</span>
        <span>{{ $t('footer.copyright.subtitle') }}</span>
      </div>
      <div class="flex flex-col md:flex-row max-lg:w-full items-center bg-white rounded-[22px] p-6 gap-y-3 md:gap-x-3">
        <h3 class="font-medium text-lg shrink-0 max-md:self-start">
          {{ $t('footer.subscribe.title') }}
        </h3>
        <custom-input :placeholder="$t('footer.subscribe.inputPlaceholder')" />
        <custom-button
          class="font-semibold py-0 h-[38px] max-md:w-full"
          pill
        >
          {{ $t('footer.subscribe.button') }}
        </custom-button>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import CustomButton from '~/ui/buttons/CustomButton.vue'
import CustomInput from '~/ui/inputs/CustomInput.vue'

const fullYear = new Date().getFullYear()
</script>
