<template>
  <custom-input
    v-model="value"
    required
    type="email"
    :label="$t(labelPath)"
    :placeholder="$t('organisation.form.email.placeholder')"
    :disabled="disabled"
    autocomplete="email"
    :invalid-feedback="errorMessage"
    :state="errorMessage ? false : null"
  />
</template>

<script setup lang="ts">

import CustomInput from '~/ui/inputs/CustomInput.vue'

defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
})
const labelPath = 'organisation.form.email.label'

const { value, errorMessage } = useField('email', {
  required: true,
  userEmail: true,
}, {
  label: useI18n().t(labelPath),
})

</script>
