<script setup lang="ts">

import NavMenuItemGroup from '~/modules/nav-menu/components/nav-menu-items/NavMenuItemGroup.vue'
import NavMenuItemLink from '~/modules/nav-menu/components/nav-menu-items/NavMenuItemLink.vue'
import { NavGroupItem } from '~/modules/nav-menu/types/NavGroupItem.type'
import { NavLinkItem } from '~/modules/nav-menu/types/NavLinkItem.type'

defineProps({
  items: {
    type: Array<NavGroupItem | NavLinkItem>,
    required: true,
  },
})

const navMenuItemsLinkOrGroup = (item: NavGroupItem | NavLinkItem) => {
  if ((item as NavGroupItem).children) {
    return NavMenuItemGroup
  }
  return NavMenuItemLink
}
</script>
<template>
  <div
    v-for="item in items"
    :key="item.title"
  >
    <component
      :is="navMenuItemsLinkOrGroup(item)"
      :item="item"
    />
  </div>
</template>
