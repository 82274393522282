<template>
  <custom-modal
    v-model="isModalOpen"
    :hide-header="true"
    :no-close-on-backdrop="true"
    :hide-footer="true"
    :centered="false"
  >
    <div class="text-xl">
      {{ t('organisation.form.id.label') }}: {{ store.organisation?.id }}
    </div>
    <div class="text-xl mb-4">
      {{ t('organisation.form.createAt.label') }}: {{ new Date(store.organisation.createTime).toLocaleString() }}
    </div>
    <form
      class="flex flex-col justify-between  h-[600px]"
      @submit="save"
    >
      <div>
        <organisation-form-input-name
          :disabled="isFetching"
        />
        <organisation-form-input-email
          class="mt-2"
          :disabled="isFetching"
        />
        <phone-input
          v-model:mobile-phone="phone"
          class="mt-2"
          :label="$t('organisation.form.phone.label')"
          :placeholder="$t('organisation.form.phone.placeholder')"
          :disabled="isFetching"
          input-class="bg-gray-50"
          :errors="phoneError ? [phoneError] : undefined"
          required
          @set-errors="setPhoneErrors"
        />
        <organisation-form-input-site
          class="mt-2"
          :disabled="isFetching"
        />
        <organisation-country-select
          v-if="addressStore.countries?.length"
          v-model="countryIsoCode"
          class="mt-2"
        />
        <suggest-address-input
          v-if="countryIsoCode"
          v-model="address"
          class="mt-2"
          close-on-select
          :code="countryIsoCode"
          :error-message="errorMessage"
          @set-errors="setErrors($event)"
        />
        <organisation-type-select
          class="mt-2"
        />
      </div>
      <div>
        <custom-button-with-spinner
          class="w-full mt-4 font-semibold"
          type="submit"
          :disabled="isFetching"
          :display-spinner="isFetching"
        >
          {{ t('organisation.form.actions.save.label') }}
        </custom-button-with-spinner>
      </div>
    </form>
  </custom-modal>
</template>

<script setup lang="ts">
import useOrganisationStore from '~/modules/organisation/store'
import CustomButtonWithSpinner from '~/ui/buttons/CustomButtonWithSpinner.vue'
import { useField, useForm } from 'vee-validate'
import { useAddressStore } from '~/modules/address/store'
import SuggestAddressInput from '~/modules/organisation/components/SuggestAddressInput.vue'
import { Organisation } from '~/common/types/organisation/Organisation'
import { OrganisationService } from '~/modules/organisation/service/OrganisationService'
import { Address } from '~/common/types/address/Address'
import { COLOR } from '~/ui/constants/color'
import CustomModal from '~/ui/modals/CustomModal.vue'
import OrganisationTypeSelect from '~/modules/organisation/components/OrganisationTypeSelect.vue'
import OrganisationCountrySelect from '~/modules/organisation/components/OrganisationCountrySelect.vue'
import PhoneInput from '~/components/inputs/PhoneInput.vue'
import OrganisationFormInputName from '~/modules/organisation/components/OrganisationFormInputName.vue'
import OrganisationFormInputEmail from '~/modules/organisation/components/OrganisationFormInputEmail.vue'
import OrganisationFormInputSite from '~/modules/organisation/components/OrganisationFormInputSite.vue'
import { Countries } from '~/common/constants/country/Countries'
import { useUserStore } from '~/modules/user/UserModule'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: boolean): void}>()

const { t } = useI18n()
const store = useOrganisationStore()
const userStore = useUserStore()
const addressStore = useAddressStore()
const service = new OrganisationService()

const isFetching = ref(false)

const isModalOpen = computed({
  get: () => props.modelValue,
  set: value => { emits('update:modelValue', value) },
})

const { values, setErrors, handleSubmit } = useForm<Omit<Organisation, 'photo' | 'invitationSecret' | 'countryIsoCode'>>({
  initialValues: {
    id: store.organisation?.id,
    name: store.organisation?.name || '',
    type: store.organisation?.type || null,
    createTime: store.organisation?.createTime || '',
    email: store.organisation?.email || userStore.user.email || '',
    address: store.organisation?.address || null,
    country: store.organisation?.country || null,
    site: store.organisation?.site || null,
  },
})

const countryIsoCode = ref<keyof typeof Countries | null>(store.organisation?.country?.id ? null : store.organisation?.country?.ISO as keyof typeof Countries)

const { value: phone, errorMessage: phoneError, setErrors: setPhoneErrors } = useField<string>('phone', { required: true }, {
  label: t('organisation.form.phone.label'),
  initialValue: store.organisation?.phone || userStore.user.mobilePhone || '',
  syncVModel: false,
})

const { value: address, errorMessage, setErrors: setAddressErrors } = useField<Address | null>('address', { required: true }, {
  label: t('organisation.form.city.label'),
  initialValue: store.organisation?.address,
  syncVModel: false,
})

const save = handleSubmit(() => {
  isFetching.value = true
  if (!countryIsoCode.value) { return }
  service.setOrganisation({ ...values, countryIsoCode: countryIsoCode.value })
    .then(() => {
      toast(t('organisation.form.toast.success.label'), { variant: COLOR.success })
      isFetching.value = false
      store.isDisplayOrganisationSettingsModal = false
    })
    .catch(error => {
      toast(t('organisation.form.toast.error.label'), { variant: COLOR.warning })
      if (error.status === 422) {
        const errors = error?.data?.errors
        if (errors) {
          setErrors(errors)
        }
      }
    })
    .finally(() => {
      isFetching.value = false
    })
})

onBeforeMount(() => {
  setAddressErrors([])
})
</script>
