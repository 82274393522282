<template>
  <div class="flex items-center">
    <custom-avatar
      v-if="user.photo"
      :src="userImageLink"
      rounded
      :alt="$t('user.profile.avatar.alt', {user: user.firstName})"
      class="mr-4"
      size="lg"
    />
    <default-avatar
      v-if=" !user.photo"
      class=" mr-4"
      size="lg"
      rounded
    />
    <div>
      <div class="text-primary font-semibold text-lg">
        {{ user.firstName }} {{ user.lastName ?? '' }}
      </div>
      <nuxt-link
        class="text-gray-500"
        :to="{name: ROUTES_NAMES.profile}"
      >
        {{ $t('user.profile.personalCabinet.goTo.label') }}
      </nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import CustomAvatar from '~/ui/avatars/CustomAvatar.vue'
import { useUserStore } from '~/modules/user/store'
import DefaultAvatar from '~/components/common/DefaultAvatar.vue'
import { ROUTES_NAMES } from '~/router/constants/Router.routesNames'

const runtimeConfig = useRuntimeConfig()

const store = useUserStore()

const { quickDealAppUrl } = runtimeConfig.public

const user = computed(() => store.user)

const userImageLink = computed(() => (user.value.photo ? `${quickDealAppUrl}/img/user/logo/${user.value.id}/${user.value.photo}` : ''))

</script>
