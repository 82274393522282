<template>
  <custom-button-group
    v-model="selectedCurrency"
    :options="entityOptions"
    class="h-[38px]"
    button-class="flex items-center font-semibold"
    :is-adaptive="false"
  />
</template>

<script setup lang="ts">
import CustomButtonGroup from '~/ui/buttons/CustomButtonGroup.vue'
import { CURRENCY } from '~/common/constants/money/Currency'
import { useAppStateStore } from '~/store/app'

const appStateStore = useAppStateStore()

const { t } = useI18n()

const currencies = Object.values(CURRENCY)

const selectedCurrency = computed({
  get: () => appStateStore.currency,
  set: value => { appStateStore.setCurrency(value) },
})

const entityOptions = computed(() => currencies.map(currency => ({ value: currency, label: t(`currency.options.${currency}`) })))
</script>
