<template>
  <header
    id="header"
    :class="[maxWidthClass, headerClass]"
    class="fixed z-20 w-full top-0 left-0"
  >
    <slot name="header">
      <main-menu class="container w-full" />
    </slot>
  </header>

  <main-content
    :class="[maxWidthClass, mainContentClass]"
    class="min-h-[calc(100%-theme(spacing[main-menu-height]))] mt-main-menu-height overflow-x-clip"
  >
    <slot />
  </main-content>
  <main-footer class="pb-[calc(env(safe-area-inset-bottom)+7rem)] md:pb-4 md:pb-[calc(env(safe-area-inset-bottom)+1rem)]" />
  <scroll-to-top />
  <feedback-widget/>
  <favorites-links-widget/>
  <app-cookie-modal/>
  <app-bottom-menu
    v-if="!isMd"
    :class="maxWidthClass"
    class="overflow-x-hidden"
  />
  <organisation-settings-modal
    v-if="isDisplayOrganisationSettingsModal"
    v-model="isDisplayOrganisationSettingsModal"
  />
  <organisation-access-status-blocking-modal
    v-if="organisationStore.organisation && organisationStore.organisation.emAccessStatus && isDisplayAccessStatusBlockingModal && !isDisplayOrganisationSettingsModal"
    v-model="isDisplayAccessStatusBlockingModal"
    :organisation="organisationStore.organisation"
  />
</template>

<script setup lang="ts">
import MainContent from '~/components/app/layout/MainContent.vue'
import ScrollToTop from '~/components/buttons/ScrollToTop.vue'
import AppBottomMenu from '~/components/menus/AppBottomMenu.vue'
import AppCookieModal from '~/components/app/AppCookieModal.vue'
import { MainMenu } from '~/modules/main-menu/MainMenuModule'
import OrganisationSettingsModal from '~/modules/organisation/components/OrganisationSettingsModal.vue'
import useOrganisationStore from '~/modules/organisation/store'
import { useAppStateStore } from '~/store/app'
import OrganisationAccessStatusBlockingModal
  from '~/modules/organisation/components/OrganisationAccessStatusBlockingModal.vue'
import { useWindowSize } from '@vueuse/core'
import MainFooter from '~/components/app/layout/MainFooter.vue'
import FeedbackWidget from '~/modules/widget/components/feedback-widget/FeedbackWidget.vue'
import FavoritesLinksWidget from '~/modules/widget/components/favorites-links/FavoritesLinksWidget.vue'

defineProps({
  headerClass: {
    type: [String, Object, Array],
    default: '',
  },
  mainContentClass: {
    type: [String, Object, Array],
    default: '',
  },
})

const organisationStore = useOrganisationStore()

const { width } = useWindowSize()

const maxWidthClass = computed(() => `max-w-[${width.value}px]`)

const isDisplayOrganisationSettingsModal = computed({
  get: () => organisationStore.isDisplayOrganisationSettingsModal,
  set: newValue => {
    organisationStore.setDisplayOrganisationSettingsModal(newValue)
  },
})
const isMd = computed(() => useAppStateStore().breakpoints.isMd)

const isDisplayAccessStatusBlockingModal = computed({
  get: () => organisationStore.isDisplayAccessStatusBlockingModal ?? !organisationStore.isOrganisationStatusGranted,
  set: value => {
    organisationStore.setIsDisplayAccessStatusBlockingModal(value)
  },
})
const showOrganizationSettingsModal = () => {
  if (organisationStore.organisation?.createTime && (!organisationStore.organisation.name || !organisationStore.organisation.address ||
    !organisationStore.organisation.type || !organisationStore.organisation.email ||
    !organisationStore.organisation.phone)) {
    organisationStore.setDisplayOrganisationSettingsModal(true)
    return true
  }
  organisationStore.setDisplayOrganisationSettingsModal(false)
  return false
}

watchEffect(showOrganizationSettingsModal)

</script>

<style scoped>
header {
  padding-top: env(safe-area-inset-top);
  @apply h-[calc(env(safe-area-inset-top)+theme(spacing[main-menu-height]))]
}
</style>
