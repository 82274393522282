<template>
  <custom-button-group
    v-model="selectedLocale"
    :options="entityOptions"
    class="h-[38px]"
    button-class="flex items-center font-semibold"
  />
</template>

<script setup lang="ts">
import CustomButtonGroup from '~/ui/buttons/CustomButtonGroup.vue'

const { locales, setLocale, locale: currentLocale } = useI18n()

const selectedLocale = computed({
  get: () => currentLocale.value,
  set: value => {
    setLocale(value)
  },
})

const entityOptions = computed(() => locales.value.map(locale => {
  let localeString = ''

  if (typeof locale === 'string') {
    localeString = locale
  } else {
    localeString = locale.code
  }

  return { value: localeString, label: localeString.toUpperCase(), icon: `flags/${localeString}`, iconFilled: true, iconClass: 'text-[24px]' }
}))
</script>
