<template>
  <div class="flex items-center">
    <custom-button
      v-if="isUnauthorized && isMd"
      variant="withoutBorders"
      class="flex items-center font-semibold"
      @click="showAuthModalOrCallback(undefined, () =>authStore.setAuthModalFormType(MODAL_FORM_DISPLAY_TYPE.login) )"
    >
      <template #prefix>
        <nuxt-icon
          name="actions/sign-in"
          class="text-[24px]"
        />
      </template>
      {{ t('auth.login.label') }}
    </custom-button>
    <custom-button
      v-if="isUnauthorized && !isMd"
      class="cursor-pointer text-primary max-h-non  "
      variant="withoutBorders"
      @click="showAuthModalOrCallback(undefined, () =>authStore.setAuthModalFormType(MODAL_FORM_DISPLAY_TYPE.login) )"
    >
      <nuxt-icon
        name="actions/sign-in"
        class="text-[25px]"
      />
    </custom-button>
    <custom-button
      v-if="isUnauthorized && isMd"
      class="!rounded-[19px] font-semibold"
      @click="showAuthModalOrCallback(undefined, () =>authStore.setAuthModalFormType(MODAL_FORM_DISPLAY_TYPE.signup) )"
    >
      {{ t('auth.register.label') }}
    </custom-button>
  </div>
</template>
<script setup lang="ts">
import {showAuthModalOrCallback} from '~/modules/auth/helpers/showAuthModalOrCallback'
import {MODAL_FORM_DISPLAY_TYPE} from '~/modules/auth/constants/Auth.modalFormDisplayType'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import {useAuthStore} from '~/modules/auth/store'
import {useUserStore} from '~/modules/user/store'
import {useAppStateStore} from '~/store/app'

const authStore = useAuthStore()

const isUnauthorized = computed(() => !useUserStore().isUserAuthorized)

const {t} = useI18n()

const isMd = computed(() => useAppStateStore().breakpoints.isMd)

</script>
