<template>
  <h4 class="text-neutral-500 font-normal">
    {{ `${t('navMenu.changeList.version')} ${version}` }}
  </h4>
</template>
<script setup lang="ts">

const { t } = useI18n()

const nuxtApp = useNuxtApp()

const version = computed(() => nuxtApp.$config.public.appVersion)

</script>
