<template>
  <custom-button
    color="negative"
    class="w-[28px] h-[24px] py-[4px] px-[10px] !rounded-[12px] leading-4"
    :disabled="isActualRoute"
    size="none"
    @click="navigateTo({name: ROUTES_NAMES.changeList})"
  >
    {{ unreadChangeListCount || 0 }}
  </custom-button>
</template>
<script setup lang="ts">
import CustomButton from '~/ui/buttons/CustomButton.vue'
import { useChangeListStore } from '~/modules/change-list/store'
import { ROUTES_NAMES } from '~/router/constants/Router.routesNames'

const changeListStore = useChangeListStore()

const unreadChangeListCount = computed(() => changeListStore.unreadChangeListCount)

const actualRoute = computed(() => useRoute().fullPath)

const isActualRoute = computed(() => actualRoute.value === `/${ROUTES_NAMES.changeList}`)

</script>
