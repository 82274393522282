<script setup lang="ts">
import CustomButton from '~/ui/buttons/CustomButton.vue'
import { NavLinkItem } from '~/modules/nav-menu/types/NavLinkItem.type'
import { useNavMenuStore } from '~/modules/nav-menu/store'
import { showAuthModalOrCallback } from '~/modules/auth/helpers/showAuthModalOrCallback'
import { ObjectHelper } from '~/common/helpers/object'
import useOrganisationStore from '~/modules/organisation/store'
import { ROUTES_NAMES } from '~/router/constants/Router.routesNames'
import { ARRAY_NAV_MENU_SHARED_ITEMS } from '~/modules/nav-menu/constants/ArrayNavMenuSharedItems'

const props = defineProps({
  item: {
    type: Object as PropType<NavLinkItem>,
    required: true,
  },
})

const store = useNavMenuStore()
const organisationStore = useOrganisationStore()
const { t } = useI18n()

const actualRoute = computed(() => useRoute().name)

const isActualRoute = computed(() => actualRoute.value === props.item.routeName)

const filledIcons = [ROUTES_NAMES.journal]

const navigate = (routeName: string) => {
  if (!isActualRoute.value && !ARRAY_NAV_MENU_SHARED_ITEMS.includes(routeName)) {
    showAuthModalOrCallback(() => {
      navigateTo({ name: routeName })
      store.toggleNavMenuDisplaying(false)
      store.toggleUnauthorizedNavMenuDisplaying(false)
    })
  } else {
    navigateTo({ name: routeName })
    store.toggleNavMenuDisplaying(false)
    store.toggleUnauthorizedNavMenuDisplaying(false)
  }
}

const buttonOrLinkAttrs = computed<Record<string, any>>(() => {
  const copiedItem = ObjectHelper.copy(props.item)

  if (organisationStore.isOrganisationStatusGranted) {
    return {
      to: { name: copiedItem.routeName },
      component: resolveComponent('NuxtLink') as string,
      onClick: () => { store.toggleNavMenuDisplaying(false) },
    }
  }
  return {
    onClick: () => { navigate(copiedItem.routeName) },
  }
})
</script>
<template>
  <custom-button
    v-bind="buttonOrLinkAttrs"
    variant="outline"
    class="border-none h-full max-h-[38px] w-full hover:text-primary rounded-none active:!text-primary"
    :class="{'text-primary cursor-no-drop' : isActualRoute}"
  >
    <template
      v-if="item.icon"
      #prefix
    >
      <nuxt-icon
        :name="item.icon"
        :filled="filledIcons.includes(item.routeName)"
        class="inline text-[24px]"
      />
    </template>
    <div
      class="ml-2 text-[18px]"
    >
      {{ t(item.title) }}
    </div>
  </custom-button>
</template>
