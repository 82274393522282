<template>
  <div
    ref="navButton"
    class="flex items-center"
    @click="store.toggleNavMenuDisplaying(!isDisplayNavMenu)"
  >
    <custom-avatar
      v-if="!isUnauthorized && user.photo"
      :src="userImageLink"
      rounded
      :alt="t('user.profile.avatar.alt', {user: user.firstName})"
      class="cursor-pointer ml-[22px]"
      size="md"
    />
    <default-avatar
      v-if="!isUnauthorized && !user.photo"
      class=" ml-[22px]"
      rounded
    />
    <div
      v-if="isMd"
      class="h-main-menu-height flex items-center px-6"
    >
      <custom-button
        class="cursor-pointer text-primary max-h-none"

        variant="withoutBorders"
        @click="store.toggleNavMenuDisplaying(!isDisplayNavMenu)"
      >
        <client-only>
          <font-awesome-icon
            class="w-[25px]"
            :icon="isDisplayNavMenu ? 'fa-xmark' : 'fa-bars'"
            :size="isDisplayNavMenu ? '3x' : '2x'"
          />
        </client-only>
      </custom-button>
    </div>
  </div>
  <custom-sidebar
    v-if="isDisplayNavMenu && isMd"
    ref="sideBar"
    class="qd-nav-menu rounded-2xl w-[362px] !h-[600px] "
  >
    <div
      class="h-full flex flex-col"
    >
      <user-profile />
      <div class="flex flex-col p-[15px] gap-y-[22px] bg-primary-light rounded-[6px] mt-[10px]">
        <div class="flex items-center">
          <nuxt-icon
            name="common/eye"
            class="text-[24px] mr-2"
          />
          <div class="mr-[20px] font-semibold">
            {{ t('navMenu.clients.label') }}
          </div>
          <custom-switch v-model="clientMode" />
        </div>
        <div class="flex gap-x-[10px]">
          <custom-button
            color="info"
            class="flex justify-center items-center w-[145px] !rounded-[19px] h-[38px] font-semibold"
            disabled
          >
            <template #prefix>
              <nuxt-icon
                name="common/motivation"
                class="text-[24px] inline-block"
              />
            </template>
            {{ t('navMenu.clients.motivation') }}
          </custom-button>
          <custom-button
            color="info"
            class="flex justify-center items-center w-[145px] !rounded-[19px] h-[38px] font-semibold"
            :disabled="actualRoute === ROUTES_NAMES.clients"
            @click="showAuthModalOrCallback(() => navigateTo({ name: ROUTES_NAMES.clients }))"
          >
            <div class="flex items-center gap-x-[5px]">
              <nuxt-icon
                name="common/lightning"
                class="text-[24px] inline-block "
              />
              <div>
                {{ t('navMenu.clients.crm') }}
              </div>
            </div>
          </custom-button>
        </div>
      </div>
      <div class="mt-4">
        <nav-menu-items :items="NAV_MENU_ITEMS" />
      </div>
      <!--      <organisation-settings-button />-->
      <div class=" self-start w-full">
        <auth-btn-logout />
        <div class=" w-full flex justify-between items-center">
          <app-version class="pl-[11px]" />
          <unread-change-list-button />
        </div>
      </div>
    </div>
  </custom-sidebar>
  <custom-drawer
    v-if="isDisplayNavMenu && !isMd"
    v-model="isDisplayNavMenu"
    full-height
  >
    <div
      class="h-full flex flex-col"
    >
      <user-profile />
      <div class="mt-4">
        <nav-menu-items :items="NAV_MENU_ITEMS" />
      </div>
      <!--      <organisation-settings-button />-->
      <div class="mt-auto self-start w-full">
        <auth-btn-logout />
        <div class=" w-full flex justify-between">
          <app-version class="pl-[11px]" />
          <unread-change-list-button />
        </div>
      </div>

    </div>
  </custom-drawer>
</template>

<script setup lang="ts">

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { onClickOutside } from '@vueuse/core'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import CustomSidebar from '~/ui/sidebars/CustomSidebar.vue'
import { useNavMenuStore } from '~/modules/nav-menu/store'
import { UserProfile, useUserStore } from '~/modules/user/UserModule'
import { useAppStateStore } from '~/store/app'
import CustomDrawer from '~/ui/drawers/CustomDrawer.vue'
import { AuthBtnLogout } from '~/modules/auth/AuthModule'
import { showAuthModalOrCallback } from '~/modules/auth/helpers/showAuthModalOrCallback'
import NavMenuItems from '~/modules/nav-menu/components/nav-menu-items/NavMenuItems.vue'
import { NAV_MENU_ITEMS } from '~/modules/nav-menu/constants/NAV_MENU_ITEMS'
import AppVersion from '~/modules/nav-menu/components/AppVersion.vue'
import { useChangeListStore } from '~/modules/change-list/store'
import UnreadChangeListButton from '~/modules/nav-menu/components/UnreadChangeListButton.vue'
import { ChangeListService } from '~/modules/change-list/services/ChangeListService'
import CustomAvatar from '~/ui/avatars/CustomAvatar.vue'
import DefaultAvatar from '~/components/common/DefaultAvatar.vue'
import CustomSwitch from '~/ui/switch/CustomSwitch.vue'
import { ROUTES_NAMES } from '~/router/constants/Router.routesNames'

const store = useNavMenuStore()
const appStore = useAppStateStore()
const changeListStore = useChangeListStore()
const changeListService = new ChangeListService()

const user = computed(() => userStore.user)

const userStore = useUserStore()

const actualRoute = computed(() => useRoute().name)

const isUnauthorized = computed(() => !userStore.isUserAuthorized)

const runtimeConfig = useRuntimeConfig()

const { quickDealAppUrl } = runtimeConfig.public

const userImageLink = computed(() => (user.value.photo ? `${quickDealAppUrl}/img/user/logo/${user.value.id}/${user.value.photo}` : ''))

const { t } = useI18n()

const clientMode = computed<boolean>({
  get: () => appStore.clientMode,
  set: value => {
    appStore.setClientMode(value)
  },
})

const isDisplayNavMenu = computed({
  get: () => store.isDisplayNavMenu,
  set: value => {
    store.toggleNavMenuDisplaying(value)
  },
})
const sideBar = ref<HTMLDivElement | null>(null)
const navButton = ref<HTMLButtonElement | null>(null)
const isMd = computed(() => appStore.breakpoints.isMd)

onClickOutside(sideBar, () => {
  if (appStore.openElementsCount.modals === 0) {
    store.toggleNavMenuDisplaying(false)
  }
}, { ignore: [navButton] })

watch(isDisplayNavMenu, () => {
  if (isDisplayNavMenu.value) {
    changeListService.getCountUnreadChangeList()
      .then(count => {
        changeListStore.setUnreadChangeListCount(count)
      })
  }
})

</script>

<style lang="scss">
@import "assets/css/scss/base/variables/main-menu";

.qd-nav-menu {
  top: calc($main-menu-height + 10px);
  height: calc(100% - $main-menu-height - 10px);
}

</style>
