<template>
  <custom-input
    v-model.trim="inputValue"
    :placeholder="placeholder"
    color="primary"
  >
    <template #suffixInner>
      <client-only>
        <nuxt-icon
          class="nuxt-icon text-neutral-500 text-[20px]"
          name="common/search"
        />
      </client-only>
    </template>
  </custom-input>
</template>
<script setup lang="ts">
import CustomInput from '~/ui/inputs/CustomInput.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: string): void}>()

const inputValue = computed({
  get() {
    return props.modelValue
  },
  set(value: string) {
    emits('update:modelValue', value)
  },
})
</script>

